<template>
    <div id="app">
        <TransitionSlide>
            <div class="global-preloader" v-if="preloader">
                <div>
                    <div />
                </div>
            </div>
        </TransitionSlide>
        <!-- <TransitionScale>
            <div class="component-ready-loader" v-if="!componentReady">
                <div class="spinner"/>
            </div>
        </TransitionScale> -->

        <!-- Ошибка при инициализации плагина -->
        <TransitionSlide>
            <div class="auth-exception-message" v-if="authException">
                <div class="exception-message-header">
                    <div class="header-caption">Произошла ошибка</div>
                    <div class="header-close" @click="authException = null" />
                </div>
                <div class="exception-message-body">
                    <!-- Обрабатываем ошибки Ру-Токена -->
                    <div v-if="authException === 'RU_TOKEN_EXTENSION_NOT_FOUND'">
                        <a href="https://www.rutoken.ru/products/all/rutoken-plugin/" target="_blank">Рутокен Плагин</a>
                        необходим для связи USB-устройств c браузером, он позволяет <br>опознавать устройства и работать
                        с ними.<br><br>

                        Чтобы установить плагин, необходимо загрузить соответствующий установочный файл и запустить его.
                        Следуйте указанным на экране рекомендациям и после завершения процесса установки подключите
                        USB-устройство в
                        свободный USB-порт компьютера. Подробнее <a
                            href="https://www.rutoken.ru/products/all/rutoken-plugin/" target="_blank">тут</a>
                    </div>
                    <div v-else-if="authException === 'RU_TOKEN_NOT_DEVICES_CONNECTED'">
                        Нет подключенных USB устройств. Пожалуйста, вставьте устройтво Рутокен в USB порт и повторите
                        попытку
                    </div>

                    <!-- Обрабатываем ошибки Крипто-Про -->
                    <div v-else-if="authException === 'CRYPTO_PRO_EXTENSION_NOT_FOUND'">
                        <a href="https://www.cryptopro.ru/products/cades/plugin" target="_blank">КриптоПро ЭЦП Browser
                            plug-in</a> предназначен для создания и проверки электронной подписи (ЭП) на веб-страницах и
                        поддерживает работу с широким набором алгоритмов,
                        как встроенных в операционную систему, так и доустановленных дополнительно. Подробнее <a
                            href="https://www.cryptopro.ru/products/cades/plugin" target="_blank">тут</a>
                    </div>

                    <!-- Обрабатываем ошибки JAKARTA -->
                    <div v-else-if="authException === 'JAKARTA_EXTENSION_NOT_FOUND'">
                        <a href="https://www.aladdin-rd.ru/catalog/jcwebclient/" target="_blank">JC-WebClient</a>
                        необходим для связи USB-устройств c браузером, он позволяет <br>опознавать устройства и работать
                        с ними.<br><br>

                        Чтобы установить плагин, необходимо загрузить соответствующий установочный файл и запустить его.
                        Следуйте указанным на экране рекомендациям и после завершения процесса установки подключите
                        USB-устройство в
                        свободный USB-порт компьютера. Подробнее <a
                            href="https://www.aladdin-rd.ru/catalog/jcwebclient/" target="_blank">тут</a>
                    </div>

                    <!-- Обрабатываем ошибки общие -->
                    <div v-else-if="authException === 'CERTIFICATES_NOT_FOUND'">
                        Нет доступных сертификатов
                    </div>

                    <div v-else>
                        {{ authException }}
                    </div>
                </div>
                <div class="exception-message-footer">
                    <div @click="authException = null">Понятно</div>
                </div>
            </div>
        </TransitionSlide>

        <!-- Просмотр выбранного сертификата -->
        <TransitionSlide>
            <div class="auth-certificates-listing-window z2" v-if="certificatesObjectMore">
                <div class="window-container">
                    <div class="window-header">
                        <div class="header-caption">Подробная информация о сертификате</div>
                        <div class="header-close" @click="certificatesObjectMore = null" />
                    </div>
                    <div class="window-body wp">
                        <pre>
                            {{ certificatesObjectMore }}
                        </pre>
                    </div>
                </div>
            </div>
        </TransitionSlide>

        <!-- Список доступных сертификатов -->
        <TransitionSlide>
            <div class="auth-certificates-listing-window" v-if="certificatesObject">
                <div class="window-container">
                    <div class="window-header">
                        <div class="header-caption">Список доступных сертификатов</div>
                        <div class="header-close" @click="certificatesObject = null" />
                    </div>
                    <div class="window-body wp">
                        <div class="certs-items"
                            v-if="certificatesObject && certificatesObject.certs && certificatesObject.certs.length > 0">
                            <div class="cert-item" v-for="(item, index) in certificatesObject.certs"
                                @click="viewCertificate(item.id)" :key="index">
                                <div class="cert-icon" />
                                <div class="cert-information">
                                    <div class="cert-name">{{ item.name }}</div>
                                    <div class="cert-date" v-if="item.validToUTCLocale">Действует до:
                                        {{ item.validToUTCLocale }} <span v-if="item.expired">(Просрочено)</span></div>
                                    <div class="cert-date" v-else>Не удалось определить дату действия подписи</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </TransitionSlide>

        <div class="component-ready-container">
            <div class="general-vertical-box">
                <!-- <div class="auth-header-caption">Авторизация</div> -->
                <div class="auth-header-sub-caption">Пожалуйста, выберите тип ЭЦП</div>
                <div class="login-application-button">
                    <ECPButton type="ru-token" @auth="onAuth" />
                    <ECPButton type="crypto-pro" @auth="onAuth" />
                    <ECPButton type="jakarta" @auth="onAuth" />

                    <!-- @auth-exception="authException = $event" @certificates-object="certificatesObject = $event" -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { postApi } from '@/run'
import CryptoECP from "./app/lib/crypto";
import TransitionScale from "./TransitionScale";
import ECPButton from "./ECPButton";
import TransitionSlide from "./TransitionSlide";
export default {
    name: 'App',
    components: {
        TransitionSlide,
        TransitionScale,
        ECPButton
    },
    data() {
        return {
            crypto: null,
            componentReady: false,
            preloader: false,
            authException: null,
            certificatesObject: null,
            certificatesObjectMore: null,
        }
    },
    computed: {

    },
    mounted() {
        this.componentReady = false;
        this.ready();
    },
    methods: {
        isValidDate(date) {
            return date instanceof Date && !isNaN(date);
        },
        ready() {
            this.componentReady = true;
            // window.onload = () => {
            //     this.componentReady = true;
            // }
        },
        onAuth(ecpType) {
            this.preloader = true;
            const ecp = (ecpType === 'ru-token') ?
                CryptoECP.ECP_RU_TOKEN : (ecpType === 'crypto-pro') ?
                    CryptoECP.ECP_CRYPTO_PRO : (ecpType === 'jakarta') ?
                        CryptoECP.ECP_JAKARTA : null;
            this.crypto = new CryptoECP(ecp);
            this.crypto.init((ecpInfo) => {
                this.certificatesObject = ecpInfo;
                this.preloader = false;
            }, (initException) => {
                this.authException = initException;
                this.preloader = false;
            });
        },
        viewCertificate(id) {
            this.preloader = true;
            this.crypto.getCertificate(id, (certificate) => {
                console.log(id);
                console.log(certificate);




                this.crypto.readCertificate(id, (certificate) => {
                    // console.log(id);
                    // console.log(certificate);

                    postApi('/loginEcp', {
                        certificate: certificate,
                        sha: id
                    }).then(response => {

                    })

                    // this.certificatesObjectMore = certificate;
                    // this.preloader = false;
                }, (e) => {
                    // this.authException = e;
                    // this.preloader = false;
                    // this.certificatesObjectMore = null;
                });



                this.certificatesObjectMore = certificate;
                this.preloader = false;
            }, (e) => {
                this.authException = e;
                this.preloader = false;
                this.certificatesObjectMore = null;
            });


        }
    }
}
</script>

<style lang="scss">
$--steel_gray_10: #fafbfc;
$--steel_gray_20: #f7f8fa;
$--steel_gray_40: #f0f2f5;
$--steel_gray_60: #edeef0;
$--steel_gray_80: #e7e8ec;
$--steel_gray_100: #dce1e6;
$--steel_gray_120: #d3d9de;
$--steel_gray_140: #c5d0db;
$--steel_gray_200: #aeb7c2;
$--steel_gray_300: #606f82;
$red-color-500: #F44336;
$red-color-600: #E53935;
$red-color-error: #EB344C;
$lab-button-radius: 6px;

@mixin spinner($color: #0288d1, $size: 32px) {
    display: inline-block;
    width: $size + 5;
    height: $size + 3;

    &:after {
        content: " ";
        display: block;
        width: $size;
        height: $size;
        border-radius: 50%;
        border: 2px solid $color;
        border-color: $color transparent $color transparent;
        animation: dual-ring 1.2s linear infinite;
    }

    @keyframes dual-ring {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }
}

@mixin flex {
    display: -webkit-flex;
    display: flex;
}

@mixin flex-row {
    display: -webkit-flex;
    display: flex;
    flex-direction: row;
}

@mixin flex-center-row {
    display: -webkit-flex;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

@mixin flex-center-column {
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@mixin box-shadow-container {
    box-shadow: 0 8px 45px rgba(56, 54, 50, 0.08);
}

@mixin ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@mixin grayScrollBar() {
    * {
        scrollbar-width: auto;
    }

    &::-webkit-scrollbar {
        height: 16px;
    }

    &::-webkit-scrollbar-track {
        background: #ffffff;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 8px;
        border: 3px solid #ffffff;
        background-color: #DADADA;
        transition: all .2s ease;

        &:hover {
            background-color: #989898;
        }
    }
}

button,
hr,
input {
    overflow: visible
}

progress,
sub,
sup {
    vertical-align: baseline
}

[type=checkbox],
[type=radio],
legend {
    box-sizing: border-box;
    padding: 0
}

html {
    line-height: 1.15;
    -webkit-text-size-adjust: 100%
}

body {
    margin: 0
}

details,
main {
    display: block
}

h1 {
    font-size: 2em;
    margin: .67em 0
}

hr {
    box-sizing: content-box;
    height: 0
}

code,
kbd,
pre,
samp {
    font-family: monospace, monospace;
    font-size: 1em
}

a {
    background-color: transparent
}

abbr[title] {
    border-bottom: none;
    text-decoration: underline;
    text-decoration: underline dotted
}

b,
strong {
    font-weight: bolder
}

small {
    font-size: 80%
}

sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative
}

sub {
    bottom: -.25em
}

sup {
    top: -.5em
}

img {
    border-style: none
}

button,
input,
optgroup,
select,
textarea {
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
    margin: 0
}

button,
select {
    text-transform: none
}

[type=button],
[type=reset],
[type=submit],
button {
    -webkit-appearance: button
}

[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner,
button::-moz-focus-inner {
    border-style: none;
    padding: 0
}

[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring,
button:-moz-focusring {
    outline: ButtonText dotted 1px
}

fieldset {
    padding: .35em .75em .625em
}

legend {
    color: inherit;
    display: table;
    max-width: 100%;
    white-space: normal
}

textarea {
    overflow: auto
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
    height: auto
}

[type=search] {
    -webkit-appearance: textfield;
    outline-offset: -2px
}

[type=search]::-webkit-search-decoration {
    -webkit-appearance: none
}

::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit
}

summary {
    display: list-item
}

[hidden],
template {
    display: none
}

body {
    font-family: "Roboto", serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-variation-settings: "wdth" 100;
    font-size: 13px;
    background-color: #eceff1;
}

#app {
    &>div.global-preloader {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 10000;
        background-color: rgba(31, 47, 65, 0.2);
        @include flex-center-row;

        &>div {
            flex: 0 0 80px;
            height: 80px;
            border-radius: 4px;
            background-color: #fff;
            @include box-shadow-container;
            @include flex-center-row;

            &>div {
                @include spinner();
            }
        }
    }

    &>div.component-ready-loader {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 10000;
        background-color: #eceff1;
        @include flex-center-row;

        &>div.spinner {
            @include spinner();
        }
    }

    &>div.auth-exception-message {
        position: fixed;
        background-color: rgba(31, 47, 65, 0.9);
        border-radius: 4px;
        height: auto;
        top: 33px;
        z-index: 100000;
        border-bottom: 4px solid #F44336;
        transition: opacity .3s, visibility .3s, top .3s ease;
        width: 728px;
        left: calc(50% - 364px);
        color: #fff;

        &>div.exception-message-header {
            padding: 12px;
            padding-bottom: 0;
            @include flex-center-row;

            &>div.header-caption {
                width: 0;
                flex: 1;
                font-weight: 500;
            }

            &>div.header-close {
                margin-left: 24px;
                flex: 0 0 8px;
                height: 8px;
                background-image: url('~@/../public/img/icons/icon-close_white.svg');
                background-repeat: no-repeat;
                background-position: center;
                cursor: pointer;
                transition: all .2s ease;
            }
        }

        &>div.exception-message-body {
            padding: 12px;

            a {
                color: #fff;
            }
        }

        &>div.exception-message-footer {
            padding-left: 12px;
            padding-right: 12px;
            margin-bottom: 12px;
            @include flex-row;

            &>div {
                background-color: #fff;
                padding: 12px;
                padding-top: 8px;
                padding-bottom: 8px;
                border-radius: 4px;
                color: #1f1f1f;
                cursor: pointer;
            }
        }
    }

    &>div.auth-certificates-listing-window {
        position: fixed;
        background-color: rgba(31, 47, 65, 0.2);
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 9999;
        @include flex-center-row;

        &.z2 {
            z-index: 10000;
        }

        &>div.window-container {
            flex: 0 0 720px;
            background-color: #fff;
            border-radius: 4px;

            &>div.window-header {
                padding: 12px;
                border-bottom: 1px solid #e7e8ec;
                @include flex-center-row;

                &>div.header-caption {
                    width: 0;
                    flex: 1;
                    font-weight: 500;
                }

                &>div.header-close {
                    margin-left: 24px;
                    flex: 0 0 8px;
                    height: 8px;
                    background-image: url('~@/../public/img/icons/icon-close_black.svg');
                    background-repeat: no-repeat;
                    background-position: center;
                    cursor: pointer;
                    transition: all .2s ease;
                }
            }

            &>div.window-body {
                overflow: auto;
                max-height: calc(100vh - 200px);
                padding: 12px;
                @include grayScrollBar;

                &.wp {
                    padding: 0;
                }

                &>fieldset {
                    border: 1px solid #e7e8ec;
                }

                &>div.error-message {
                    margin-bottom: 12px;
                    color: #F44336;
                }

                &>div.certs-items {
                    &>div.cert-item {
                        padding: 12px;
                        background-color: $--steel_gray_20;
                        transition: all .2s ease;
                        cursor: pointer;
                        @include flex-center-row;

                        &:nth-child(odd) {
                            background-color: $--steel_gray_40;
                        }

                        &>div.cert-icon {
                            background-image: url('~@/../public/img/icons/icon-certificate.png');
                            flex: 0 0 35px;
                            height: 35px;
                            background-size: cover;
                            margin-right: 12px;
                        }

                        &>div.cert-information {
                            width: 0;
                            flex: 1;

                            &>div.cert-name {
                                @include ellipsis;
                                font-weight: 500;
                                margin-bottom: 2px;
                            }

                            &>div.cert-date {
                                font-size: 12px;

                                &>span {
                                    font-weight: 500;
                                    color: $red-color-500;
                                }
                            }
                        }

                        &:hover {
                            background-color: $--steel_gray_100;
                        }
                    }
                }
            }
        }
    }

    &>div.component-ready-container {
        width: 100%;
        // height: 100vh;
        @include flex-center-row;

        &>div.general-vertical-box {
            //background-color: #ccc;
            flex: 0 0 300px;

            &>div.auth-header-caption {
                text-align: center;
                font-size: 28px;
                line-height: 32px;
                font-weight: 300;
                margin-bottom: 4px;
            }

            &>div.auth-header-sub-caption {
                text-align: center;
                font-size: 14px;
                font-weight: 300;
                margin-bottom: 32px;
                color: #999;
            }

            &>div.login-application-button {
                // display: flex;
                flex-direction: row;

                &>div.lab {
                    background-color: #fff;
                    flex: 1;
                    height: 80px;
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: center;
                    border-radius: $lab-button-radius;
                    transition: all .2s ease;
                    cursor: pointer;
                    position: relative;
                    overflow: hidden;

                    &.ru-token {
                        background-image: url('~@/../public/img/logo-ru_token.png');
                        margin-right: 16px;
                        background-size: 90%;
                    }

                    &.crypto-pro {
                        background-image: url('~@/../public/img/logo-cryptopro.svg');
                        margin-left: 16px;
                        margin-right: 16px;
                        background-size: 70%;
                    }

                    &.jakarta {
                        margin-left: 16px;
                        @include flex-center-row;

                        &>div.logo-name {
                            font-size: 22px;
                            font-weight: 300;
                            transition: all .2s ease;
                            color: #FD7E4A;
                        }
                    }

                    &:hover {
                        box-shadow: 0 8px 45px rgba(56, 54, 50, 0.08);

                        &.ru-token {
                            background-size: 80%;
                        }

                        &.crypto-pro {
                            background-size: 60%;
                        }

                        &.jakarta {
                            &>div.logo-name {
                                color: #FD7E4A;
                                font-size: 20px;
                            }
                        }
                    }

                    &>div.lab-preloader {
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        border-radius: $lab-button-radius;
                        z-index: 1;
                        background-color: #fff;
                        @include flex-center-row;

                        &>div {
                            @include spinner();
                        }
                    }
                }
            }
        }

        &>div.footer-copy {
            position: absolute;
            bottom: 16px;
            width: 150px;
            text-align: center;
            left: calc(50% - 75px);
            color: #d3d9de;
        }
    }
}

/*background-image: url('~@/../public/img/login/background.jpg');*/
</style>
