<template>
    <transition :name="slow ? 'body__ui-transition-scale-slow' : 'body__ui-transition-scale'" mode="out-in">
        <slot/>
    </transition>
</template>
<script>
    export default {
        name: "transition-scale",
        props: {
            slow: {
                type: Boolean,
                default() {
                    return false;
                }
            },
        },
        data() {
            return {

            };
        },
    }
</script>
<style lang="scss">
    .body__ui-transition-scale-enter-active {
        transition: all .2s ease;
    }
    .body__ui-transition-scale-leave-active {
        transition: all .2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }
    .body__ui-transition-scale-enter,
    .body__ui-transition-scale-leave-to
    {
        transform: scale(1.3);
        opacity: 0;
    }

    .body__ui-transition-scale-slow-enter-active {
        transition: all .4s ease;
    }
    .body__ui-transition-scale-slow-leave-active {
        transition: all .4s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }
    .body__ui-transition-scale-slow-enter,
    .body__ui-transition-scale-slow-leave-to
    {
        transform: scale(1.1);
        opacity: 0;
    }
</style>
