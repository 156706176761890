<template>
    <transition name="body__ui-transition-slide" mode="out-in">
        <slot/>
    </transition>
</template>
<script>
    export default {
        name: "transition-slide",
        props: {

        },
        data() {
            return {

            };
        },
    }
</script>
<style lang="scss">
    .body__ui-transition-slide-enter-active {
        transition: all .15s ease;
    }
    .body__ui-transition-slide-leave-active {
        transition: all .15s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }
    .body__ui-transition-slide-enter,
    .body__ui-transition-slide-leave-to
    {
        transform: translateY(-10px);
        opacity: 0;
    }
</style>
