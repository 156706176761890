<template>
    <div :class="['lab', 'ecp-button', type]" @click="onAuth">
        <TransitionSlide>
            <div class="lab-preloader" v-if="preloader"><div/></div>
        </TransitionSlide>
        <div class="logo-name" v-if="isJakarta">JaCarta PKI</div>
    </div>
</template>
<script>
    import CryptoECP from "./app/lib/crypto";
    import TransitionSlide from "./TransitionSlide";
    export default {
        name: 'ecp-button',
        components:{
            TransitionSlide
        },
        props: {
            type: {
                type: String,
                default() {
                    return null;
                }
            },
        },
        data(){
            return {
                preloader: false,
            }
        },
        computed:{
            isRuToken(){
                return this.type === 'ru-token';
            },
            isCryptoPro(){
                return this.type === 'crypto-pro';
            },
            isJakarta(){
                return this.type === 'jakarta';
            },
        },
        methods:{
            onAuth(){
                this.$emit('auth', this.type);
                // this.$emit('auth-exception', null);
                // this.preloader = true;
                // const ecp = (this.isRuToken) ? CryptoECP.ECP_RU_TOKEN : (this.isCryptoPro) ? CryptoECP.ECP_CRYPTO_PRO : (this.isJakarta) ? CryptoECP.ECP_JAKARTA : null;
                // const crypto = new CryptoECP(ecp);
                // crypto.init((ecpInfo) => {
                //     this.preloader = false;
                //     console.log('ecpInfo', ecpInfo)
                //     if(ecpInfo.version && ecpInfo.certs){
                //         this.$emit('certificates-object', ecpInfo);
                //     }
                // }, (initException) => {
                //     this.$emit('auth-exception', initException);
                //     this.preloader = false;
                // });
                /*if(this.isRuToken){
                    crypto.ruTokenCertificates((object) => {
                        this.preloader = false;
                        if(object.init && object.certs){
                            this.$emit('certificates-object', object);
                        }
                    }, (e) => {
                        console.log('RU TOKEN EXCEPTION', e);
                        this.$emit('auth-exception', e);
                        this.preloader = false;
                    });
                }

                if(this.isCryptoPro){
                    crypto.cryptoProCertificates((object) => {
                        this.preloader = false;
                        if(object.init && object.certs){
                            this.$emit('certificates-object', object);
                        }
                    }, (e) => {
                        console.log('CRYPTO PRO EXCEPTION', e);
                        this.$emit('auth-exception', e);
                        this.preloader = false;
                    });
                }

                if(this.isJakarta){
                    crypto.jakartaCertificates((object) => {
                        this.preloader = false;
                        if(object.init && object.certs){
                            this.$emit('certificates-object', object);
                        }
                    }, (e) => {
                        console.log('CRYPTO PRO EXCEPTION', e);
                        this.$emit('auth-exception', e);
                        this.preloader = false;
                    });
                }*/
                //this.preloader = true;
            }
        }
    }
</script>
