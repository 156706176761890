import { RuToken, CryptoPro, JaCarta2 } from "./dependencies";
export default class CryptoECP{
    static ECP_RU_TOKEN = 'RU_TOKEN';
    static ECP_CRYPTO_PRO = 'CRYPTO_PRO';
    static ECP_JAKARTA = 'JAKARTA';
    static ERROR_ECP_CLASS_NAME = 'ECP_CLASS_NAME_INCORRECT';

    constructor(className) {
        this.currentDate = new Date();
        this.className = (className === CryptoECP.ECP_RU_TOKEN) ? new RuToken :
            (className === CryptoECP.ECP_CRYPTO_PRO) ? new CryptoPro :
                (className === CryptoECP.ECP_JAKARTA) ? new JaCarta2
                    : null;
        this.ECPType = (className === CryptoECP.ECP_RU_TOKEN) ? CryptoECP.ECP_RU_TOKEN :
            (className === CryptoECP.ECP_CRYPTO_PRO) ? CryptoECP.ECP_CRYPTO_PRO :
                (className === CryptoECP.ECP_JAKARTA) ? CryptoECP.ECP_JAKARTA
                    : null;
        this.version = null;
    }

    static isString(string) {
        if (typeof string === 'string' || string instanceof String) {
            return true;
        }
        return false;
    }

    static isNull(v) {
        return v === null || v === 'null';
    }

    static isEmpty(string) {
        return (!string || 0 === string.length);
    }

    static isFunction(str){
        return typeof(str) === 'function';
    }

    static isArray(v) {
        return Object.prototype.toString.call(v) === '[object Array]';
    }

    static isObject(v) {
        return v instanceof Object;
    }

    static quote(string) {
        if (CryptoECP.isNull(string) || !CryptoECP.isString(string) || CryptoECP.isEmpty(string)) {
            return string
        }

        return string.replace(/(?:"([^>]*)")(?!>)/g, '«$1»').replace('"', '').replace('"', '').replace('"', '').replace('"', '');
    }

    static isValidDate(date) {
        return date instanceof Date && !isNaN(date);
    }

    static dateFormat(x) {
        if (x == null) return x;
        return  "" + (x.getDate() < 10 ? "0" : "") + (x.getDate())
            + "." + (x.getMonth() < 9 ? "0" : "") + (x.getMonth() + 1)
            + "." + x.getFullYear();
    }

    init(success = null, error = null){
        if(CryptoECP.isObject(this.className) && this.ECPType != null){
            this.className.init().then((version) => {
                this.version = version ? version.version : null;
                this.getCertificates((certs) => {
                    const certificates = certs;
                    certificates.forEach((cert) => {
                        if(CryptoECP.isObject(cert)){
                            cert.expired = false;
                            if (cert.hasOwnProperty('name')) cert.name = CryptoECP.quote(cert.name);
                            if ((cert.hasOwnProperty('validFrom') && cert.validFrom && CryptoECP.isValidDate(new Date(cert.validFrom)))
                                && (cert.hasOwnProperty('validTo') && cert.validTo && CryptoECP.isValidDate(new Date(cert.validTo))))
                            {
                                cert.validToUTC = new Date(cert.validTo);
                                cert.validToUTCLocale = CryptoECP.dateFormat(cert.validToUTC);
                                if(this.currentDate.getTime() > cert.validToUTC.getTime()){
                                    cert.expired = true;
                                }
                            }
                        }
                    });

                    if(CryptoECP.isArray(certificates) && certificates.length > 0){
                        try{
                            certificates.sort((a, b) => {
                                return new Date(b['validTo']).getTime() - new Date(a['validTo']).getTime();
                            });
                        }
                        catch (e) {
                            console.log('Date validTo not sorting', e);
                        }

                        if(CryptoECP.isFunction(success)){
                            success({ ecpType: this.ECPType, version: this.getVersion(), certs: certificates });
                        }
                    }
                    else
                    {
                        if(CryptoECP.isFunction(error)){
                            error('CERTIFICATES_NOT_FOUND');
                        }
                    }
                }, (certsException) => {
                    if(CryptoECP.isFunction(error)){
                        error(certsException);
                    }
                });
            }).catch((e) => {
                const message = (e && e.message) ? e.message : e;
                if(CryptoECP.isFunction(error)){
                    error(message);
                }
            });
        }
        else
        {
            if(CryptoECP.isFunction(error)){
                error(CryptoECP.ERROR_ECP_CLASS_NAME);
            }
        }
    }

    getVersion(){
        return this.version;
    }

    getCertificates(success = null, error = null){
        if(CryptoECP.isObject(this.className) || CryptoECP.isFunction(this.className.listCertificates())){
            if(this.ECPType === CryptoECP.ECP_RU_TOKEN){
                this.className.listCertificates().then((certs) => {
                    if(CryptoECP.isFunction(success)){
                        success(certs);
                    }
                }).then(null, e => {
                    const message = (e && e.message) ? e.message : e;
                    if(CryptoECP.isFunction(error)){
                        error(message);
                    }
                });
            }
            else
            {
                this.className.listCertificates().then((certs) => {
                    if(CryptoECP.isFunction(success)){
                        success(certs);
                    }
                }).catch((e) => {
                    const message = (e && e.message) ? e.message : e;
                    if(CryptoECP.isFunction(error)){
                        error(message);
                    }
                });
            }
        }
    }

    getCertificate(id = null, success = null, error = null){
        if(this.ECPType === CryptoECP.ECP_RU_TOKEN){
            this.className.certificateInfo(id).then((certificate) => {
                if(CryptoECP.isFunction(success)){
                    success(certificate);
                }
            }).then(null, e => {
                const message = (e && e.message) ? e.message : e;
                if(CryptoECP.isFunction(error)){
                    error(message);
                }
            });
        }
        else
        {
            this.className.certificateInfo(id).then((certificate) => {
                if(CryptoECP.isFunction(success)){
                    success(certificate);
                }
            }).catch((e) => {
                const message = (e && e.message) ? e.message : e;
                if(CryptoECP.isFunction(error)){
                    error(message);
                }
            });
        }
    }

    readCertificate(id = null, success = null, error = null){
        if(this.ECPType === CryptoECP.ECP_RU_TOKEN){
            this.className.readCertificate(id).then((certificate) => {
                if(CryptoECP.isFunction(success)){
                    success(certificate);
                }
            }).then(null, e => {
                const message = (e && e.message) ? e.message : e;
                if(CryptoECP.isFunction(error)){
                    error(message);
                }
            });
        }
        else
        {
            this.className.readCertificate(id).then((certificate) => {
                if(CryptoECP.isFunction(success)){
                    success(certificate);
                }
            }).catch((e) => {
                const message = (e && e.message) ? e.message : e;
                if(CryptoECP.isFunction(error)){
                    error(message);
                }
            });
        }
    }
}
